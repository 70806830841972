import {
    Bar,
    DatafeedConfiguration,
    DatafeedErrorCallback,
    DOMCallback,
    HistoryCallback,
    LibrarySymbolInfo,
    PeriodParams,
    ResolutionString,
    SearchSymbolResultItem,
    SubscribeBarsCallback,
    SymbolResolveExtension
} from "../../../assets/lib/charting_library"
import {
    DatafeedQuoteValues,
    IDatafeedChartApi,
    IDatafeedQuotesApi,
    IExternalDatafeed,
    QuoteOkData,
    QuotesCallback,
    QuotesErrorCallback
} from "../../../assets/lib/charting_library/datafeed-api"
import {defaultConfig, IConfig} from "../../../context/config"
import {IAuth} from "../../../context/auth"
import {$fetch} from "../../../assets/utils/fetch"
import {WebSocketsTicker} from "../websockets/WebSocketsTicker"


export class DataFeed implements IExternalDatafeed,
    IDatafeedQuotesApi,
    IDatafeedChartApi {
    private readonly exchange: string = "TradeQuoMarkets"
    private readonly contextAuth: IAuth
    private static instance: DataFeed | null = null
    private symbols: LibrarySymbolInfo[] = []
    private symbolsMap: Map<string, LibrarySymbolInfo> = new Map()
    private readonly applicationConfiguration: IConfig = defaultConfig
    private configuration: DatafeedConfiguration = {
        supported_resolutions: [
            "1", "2", "3", "5", "15", "30", "60", "120", "240", "360", "480", "720", "1D", "3D", "1W", "1M"
        ] as ResolutionString[],
        exchanges: [{value: this.exchange, name: this.exchange, desc: this.exchange + " LTD"}],
        symbols_types: [],
        supports_marks: true,
        supports_timescale_marks: true,
        supports_time: false
    }
    resolutionMapper: Record<string, number> = {
        "1": 1,
        "2": 2,
        "3": 3,
        "5": 5,
        "15": 15,
        "30": 30,
        "60": 60,
        "120": 120,
        "240": 240,
        "360": 360,
        "480": 480,
        "720": 720,
        "1D": 1440,
        "3D": 4320,
        "5D": 7200,
        "1W": 10080,
        "1M": 43200
    }
    public lastTickData: Map<string, { ask: number, bid: number }> = new Map()
    private prevDaysBarsCache: Map<string, Bar[]> = new Map()
    public quoteTickStateForTicket: Map<string, DatafeedQuoteValues> = new Map()
    private quoteState: Map<string, QuoteOkData> = new Map()
    private lastBarsCache: Map<string, Map<string, Bar>> = new Map()
    private subscribers: Map<string, {
        symbolInfo: LibrarySymbolInfo;
        resolution: string;
        onRealtimeCallback: SubscribeBarsCallback;
    }> = new Map()
    private quoteSubscribers: Map<string, {
        symbols: string[];
        fastSymbols: string[];
        onRealtimeCallback: QuotesCallback;
    }> = new Map()

    private depthSubscribers: Map<string, {
        symbol: string;
        callback: DOMCallback;
    }> = new Map()

    constructor(applicationConfiguration: IConfig = defaultConfig, contextAuth: IAuth) {
        this.applicationConfiguration = applicationConfiguration
        this.contextAuth = contextAuth
    }

    static getInstance(applicationConfiguration: IConfig = defaultConfig, contextAuth: IAuth): DataFeed {
        if (!DataFeed.instance) {
            DataFeed.instance = new DataFeed(applicationConfiguration, contextAuth)
        }
        return DataFeed.instance
    }

    static removeInstance(): void {
        DataFeed.instance = null
    }

    async onReady(callback: (config: DatafeedConfiguration) => void): Promise<void> {
        this.symbols = await this.getSymbols()
        this.symbols.forEach(symbol => this.symbolsMap.set(symbol.name, symbol))
        this.configuration.symbols_types = await this.getGroups()
        callback(this.configuration)
        const webSocketsTickerInstance = WebSocketsTicker.getInstance(this.applicationConfiguration, this.contextAuth)
        webSocketsTickerInstance.subscribe(this.onMessageFillLastTickData.bind(this))
        webSocketsTickerInstance.subscribe(this.onMessageUpdateBar.bind(this))
        webSocketsTickerInstance.subscribe(this.onMessageUpdateQuote.bind(this))
        webSocketsTickerInstance.subscribe(this.onMessageUpdateDepth.bind(this))
    }

    async getGroups() {
        try {
            const {loginAccountId} = this.contextAuth
            if (!loginAccountId) return []
            const response = await $fetch.get(`${this.applicationConfiguration.urlRestAPI}/api/v1/groups/?login=${loginAccountId}`)
            const data = await response.json()
            return extendedGroups(data)
        } catch (error) {
            return extendedGroups()
        }

        function extendedGroups(groups = []) {
            return [{name: "All", value: ""}, ...groups.map(i => ({name: i, value: i}))]
        }
    }

    getSymbolNames(): string[] {
        return Array.from(this.symbolsMap.keys())
    }

    async getSymbols(): Promise<LibrarySymbolInfo[]> {
        try {
            const {loginAccountId} = this.contextAuth
            const response = await $fetch.get(`${this.applicationConfiguration.urlRestAPI}/api/v1/symbols/?login=${loginAccountId}`)
            let symbols: any = (await response.json()) || []
            return this._mapperForSymbols(symbols)
        } catch (error) {
            console.error("[getSymbols]:", error)
            return []
        }
    }

    async getAllSymbols(): Promise<LibrarySymbolInfo[]> {
        try {
            const response = await $fetch.get(`${this.applicationConfiguration.urlRestAPI}/api/v1/symbols/all`)
            let symbols: any = (await response.json()) || []
            return this._mapperForSymbols(symbols)
        } catch (error) {
            console.error("[getSymbols]:", error)
            return []
        }
    }

    resolveSymbol(
        symbolId: string,
        onResolve: (symbolInfo: LibrarySymbolInfo) => void,
        onError: (err: string) => void,
        extension?: SymbolResolveExtension
    ): void {
        let symbol = this.symbols.find((symbol) => symbol.name === symbolId) as LibrarySymbolInfo
        if (!symbol) {
            setTimeout(() => onError(`Symbol not found: ${symbolId}`), 0)
            return
        }
        setTimeout(() => onResolve(symbol), 0)
    }

    searchSymbols(
        userInput: string,
        exchange: string,
        symbolType: string,
        onResult: (items: SearchSymbolResultItem[]) => void
    ): void {
        const symbols = this.symbols.filter((symbol) => {
            const matchesName = (
                symbol.name.toLowerCase().includes(userInput.toLowerCase())
                || symbol.description.toLowerCase().includes(userInput.toLowerCase())
            )
            const matchesType = symbolType === "" || symbol.type === symbolType
            // const matchesExchange = exchange === '' || symbol.exchange === exchange
            return matchesName
                // && matchesExchange
                && matchesType
        }).map(symbol => ({
            symbol: symbol.name,
            description: symbol.description,
            exchange: symbol.exchange,
            ticker: symbol.ticker,
            type: symbol.type,
            logo_urls: symbol.logo_urls,
            exchange_logo: symbol.exchange_logo
        }))

        setTimeout(() => onResult(symbols), 0)
    }

    async getBars(
        symbolInfo: LibrarySymbolInfo,
        resolution: ResolutionString,
        periodParams: PeriodParams,
        onResult: HistoryCallback,
        onError: DatafeedErrorCallback
    ): Promise<void> {
        const {from, to, firstDataRequest} = periodParams
        try {
            let bars = []
            const data = await this.fetchBars({symbol: symbolInfo.name, from, to, resolution})
            if (data && data.length) {
                bars = data
                if (firstDataRequest) {
                    if (!this.lastBarsCache.has(symbolInfo.name)) this.lastBarsCache.set(symbolInfo.name, new Map())
                    this.lastBarsCache.get(symbolInfo.name)!.set(resolution, {...bars[bars.length - 1]})
                }
            } else {
                bars = await this.fetchBars({symbol: symbolInfo.name, from: from - 360000, to, resolution})
            }

            onResult(bars, {noData: !bars.length})
        } catch (error) {
            console.log("Error: [getBars]")
            onError(JSON.stringify(error))
        }
    }

    async getQuotes(symbols: string[], onDataCallback: QuotesCallback, onErrorCallback: QuotesErrorCallback): Promise<void> {
        if (!symbols.length) return
        // const results = symbols.map((symbol) => {
        //     const symbolInfo = this.symbols.find(s => s.name === symbol)!
        //     const quoteData: QuoteOkData = {
        //         n: symbol,
        //         s: "ok",
        //         v: {
        //             short_name: symbol,
        //             exchange: this.exchange,
        //             original_name: symbol,
        //             description: symbolInfo.description || ""
        //         }
        //     }
        //     this.quoteState.set(symbol, quoteData)
        //     return quoteData
        // })
        const results = await Promise.all(symbols.map(async (symbol) => {
            const symbolInfo = this.symbolsMap.get(symbol)
            if (!symbolInfo) return null

            const now = new Date()
            const nowEndOfDay = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59))

            const todaySessionsRaw = getDaySessions(now, symbolInfo)
            const todayRange = todaySessionsRaw.length ? getTradingDayRange(now, todaySessionsRaw) : getDefaultDayRange(now)
            todayRange.end = Math.floor(nowEndOfDay.getTime() / 1000)

            const {sessions: prevSessionsRaw, date: prevDate} = getDaySessionsPrevious(now, symbolInfo)
            const prevRange = prevSessionsRaw.length ? getTradingDayRange(prevDate, prevSessionsRaw) : todayRange

            let currentBars = await this.fetchBars({
                symbol,
                from: todayRange.start,
                to: todayRange.end,
                resolution: "1D"
            })

            const prevBarsCacheKey = `${symbol}_${prevRange.start}_${prevRange.end}_1D`
            let prevBars: Bar[]
            if (this.prevDaysBarsCache.has(prevBarsCacheKey)) {
                prevBars = this.prevDaysBarsCache.get(prevBarsCacheKey)!
            } else {
                prevBars = await this.fetchBars({
                    symbol,
                    from: prevRange.start,
                    to: prevRange.end,
                    resolution: "1D"
                }) || []
                this.prevDaysBarsCache.set(prevBarsCacheKey, prevBars)
            }

            if (!currentBars || currentBars.length === 0) {
                if (prevBars.length) {
                    currentBars = prevBars
                    const {sessions: prevPrevSessions, date: prevPrevDate} = getDaySessionsPrevious(
                        prevDate,
                        symbolInfo
                    )
                    if (prevPrevSessions.length) {
                        const prevPrevRange = getTradingDayRange(prevPrevDate, prevPrevSessions)
                        const prev2BarsCacheKey = `${symbol}_${prevPrevRange.start}_${prevPrevRange.end}_1D`
                        let prev2Bars: Bar[] = []
                        if (this.prevDaysBarsCache.has(prev2BarsCacheKey)) {
                            prev2Bars = this.prevDaysBarsCache.get(prev2BarsCacheKey)!
                        } else {
                            prev2Bars = (await this.fetchBars({
                                symbol,
                                from: prevPrevRange.start,
                                to: prevPrevRange.end,
                                resolution: "1D"
                            })) || []
                            this.prevDaysBarsCache.set(prev2BarsCacheKey, prev2Bars)
                        }
                        prevBars = prev2Bars.length ? prev2Bars : []
                    } else {
                        prevBars = []
                    }
                } else {
                    currentBars = [{close: 0, open: 0, high: 0, low: 0, volume: 0}]
                }
            }

            const latestBar = currentBars[currentBars.length - 1]
            const firstBar = currentBars[0]
            const lp = latestBar.close
            const openPrice = firstBar.open

            const prevClosePrice = prevBars.length > 0 ? prevBars[prevBars.length - 1].close : openPrice
            const ch = lp - prevClosePrice
            const chp = prevClosePrice ? (ch / prevClosePrice) * 100 : 0

            const quoteData: QuoteOkData = {
                n: symbol, s: "ok", v: {
                    ch,
                    chp,
                    short_name: symbol,
                    exchange: this.exchange,
                    original_name: symbol,
                    description: symbolInfo.description || "",
                    lp,
                    ask: lp,
                    bid: lp,
                    open_price: openPrice,
                    high_price: Math.max(...currentBars.map((bar: any) => bar.high)),
                    low_price: Math.min(...currentBars.map((bar: any) => bar.low)),
                    prev_close_price: prevClosePrice,
                    volume: currentBars.reduce((sum: number, bar: any) => sum + bar.volume, 0)
                }
            }

            sanitizePrevDaysBarsCache(this.prevDaysBarsCache)
            this.quoteState.set(symbol, quoteData)
            return quoteData
        }))

        const data = results.filter((result): result is QuoteOkData => result !== null)
        onDataCallback(data)

        function getDaySessions(date: Date, symbolInfo: LibrarySymbolInfo): {
            start: string;
            end: string;
        }[] {
            const dayOfWeek = date.getUTCDay()
            const sessionIndex = (dayOfWeek + 6) % 7
            return (symbolInfo as any).library_custom_fields?.originalSessions?.[sessionIndex] || []
        }

        function getDaySessionsPrevious(currentDay: Date, symbolInfo: LibrarySymbolInfo): {
            date: Date;
            sessions: { start: string; end: string; }[];
        } {
            const candidateDay = new Date(currentDay.getTime())
            for (let i = 1; i <= 7; i++) {
                candidateDay.setUTCDate(candidateDay.getUTCDate() - 1)
                const rawDayOfWeek = candidateDay.getUTCDay()
                const dayOfWeek = (rawDayOfWeek + 6) % 7
                const candidateSessions = (symbolInfo as any).library_custom_fields?.originalSessions?.[dayOfWeek] || []
                if (candidateSessions && candidateSessions.length) return {
                    sessions: candidateSessions,
                    date: candidateDay
                }
            }
            return {date: new Date(), sessions: []}
        }

        function getTradingDayRange(day: Date, sessions: { start: string; end: string; }[]): {
            start: number;
            end: number;
        } {
            let earliestStart: number | null = null
            let latestEnd: number | null = null
            for (const session of sessions) {
                const {start, end} = session
                const [startHour, startMinute] = start.split(":").map(Number)
                const [endHour, endMinute] = end.split(":").map(Number)
                const sessionStart = Date.UTC(
                    day.getUTCFullYear(),
                    day.getUTCMonth(),
                    day.getUTCDate(),
                    startHour,
                    startMinute
                )
                let sessionEnd = Date.UTC(
                    day.getUTCFullYear(),
                    day.getUTCMonth(),
                    day.getUTCDate(),
                    endHour,
                    endMinute
                )
                earliestStart = earliestStart === null ? sessionStart : Math.min(earliestStart, sessionStart)
                latestEnd = latestEnd === null ? sessionEnd : Math.max(latestEnd, sessionEnd)
            }
            return {
                start: Math.floor(earliestStart! / 1000),
                end: Math.floor(latestEnd! / 1000)
            }
        }

        function getDefaultDayRange(day: Date): { start: number; end: number; } {
            const start = Math.floor(Date.UTC(day.getUTCFullYear(), day.getUTCMonth(), day.getUTCDate()) / 1000)
            const end = Math.floor(Date.now() / 1000)
            return {start, end}
        }

        function sanitizePrevDaysBarsCache(collection: Map<string, Bar[]>) {
            if (collection.size > 200) {
                const firstKey = collection.keys().next().value
                collection.delete(firstKey!)
            }
        }
    }

    subscribeBars(
        symbolInfo: LibrarySymbolInfo,
        resolution: ResolutionString,
        onTick: SubscribeBarsCallback,
        listenerGuid: string,
        onResetCacheNeededCallback: () => void
    ): void {
        this.subscribers.set(listenerGuid, {
            symbolInfo,
            resolution,
            onRealtimeCallback: onTick
        })
    }

    subscribeQuotes(
        symbols: string[],
        fastSymbols: string[],
        onRealtimeCallback: QuotesCallback,
        listenerGUID: string
    ): void {
        this.quoteSubscribers.set(listenerGUID, {
            symbols,
            fastSymbols,
            onRealtimeCallback
        })
    }

    subscribeDepth(symbol: string, callback: DOMCallback): string {
        const subscriberUID = `depth_${symbol}`
        this.depthSubscribers.set(subscriberUID, {symbol, callback})
        return subscriberUID
    }

    unsubscribeDepth(listenerGUID: string): void {
        const subscription = this.depthSubscribers.get(listenerGUID)
        if (subscription) this.depthSubscribers.delete(listenerGUID)
    }

    unsubscribeQuotes(listenerGUID: string): void {
        const subscription = this.quoteSubscribers.get(listenerGUID)
        if (subscription) this.quoteSubscribers.delete(listenerGUID)
    }

    unsubscribeBars(listenerGuid: string): void {
        const subscription = this.subscribers.get(listenerGuid)
        if (subscription) this.subscribers.delete(listenerGuid)
    }

    private async fetchBars(params: { symbol: string, from: number, to: number, resolution: string }) {
        const {symbol, from, to, resolution} = params
        const query = `timeframe=${this.resolutionMapper[resolution]}&from_time=${from}&to_time=${to}`
        try {
            const response = await $fetch.get(
                `${this.applicationConfiguration.urlRestAPI}/api/v1/symbols/${encodeURIComponent(symbol)}/charts?${query}`
            )
            return await response.json()
        } catch (error) {
        }
    }

    private onMessageFillLastTickData(data: any): void {
        const {symbol, ask, bid} = data
        this.lastTickData.set(symbol, {ask, bid})
    }

    private async onMessageUpdateQuote(data: any): Promise<void> {
        const {symbol, bid, ask, volume} = data

        const existingQuote = this.quoteState.get(symbol)
        if (!existingQuote) return

        const symbolInfo = this.symbolsMap.get(symbol) as any
        if (!symbolInfo) return

        const quoteValues = existingQuote.v
        const prevClosePrice = quoteValues.prev_close_price || quoteValues.open_price || bid

        quoteValues.lp = bid
        quoteValues.ask = ask
        quoteValues.bid = bid
        quoteValues.volume = volume
        quoteValues.high_price = Math.max(quoteValues.high_price || bid, bid, ask)
        quoteValues.low_price = Math.min(quoteValues.low_price || bid, bid, ask)
        quoteValues.ch = (quoteValues.lp || 0) - prevClosePrice
        quoteValues.chp = quoteValues.ch ? (quoteValues.ch / prevClosePrice) * 100 : 0

        this.quoteState.set(symbol, existingQuote)

        for (const [listenerGUID, {
            symbols,
            fastSymbols,
            onRealtimeCallback
        }] of Array.from(this.quoteSubscribers.entries())) {
            if (symbols.includes(symbol) || fastSymbols.includes(symbol)) {
                if (listenerGUID.includes("Broker")) {
                    const [, ticket = null] = listenerGUID.split("-")
                    if (!ticket) return
                    this.quoteTickStateForTicket.set(ticket, {...existingQuote.v})
                } else {
                    onRealtimeCallback([existingQuote])
                }
            }
        }
    }

    private async onMessageUpdateBar(data: any): Promise<void> {
        const {symbol, timestamp, bid, volume} = data
        const tradePrice = bid
        const tradeTime = timestamp

        for (const subscriptionItem of Array.from(this.subscribers.values())) {
            if (subscriptionItem.symbolInfo.name === symbol) {
                const resolution = subscriptionItem.resolution
                const lastBarsForSymbol = this.lastBarsCache.get(symbol) || new Map()
                const lastBar = lastBarsForSymbol.get(resolution) || {
                    time: tradeTime,
                    open: tradePrice,
                    high: tradePrice,
                    low: tradePrice,
                    close: tradePrice,
                    volume: volume
                }
                const nextBarTime = this.getNextBarTime(lastBar.time, resolution)

                let updatedBar
                if (tradeTime >= nextBarTime) {
                    updatedBar = {
                        time: nextBarTime,
                        open: tradePrice,
                        high: tradePrice,
                        low: tradePrice,
                        close: tradePrice,
                        volume: volume
                    }
                } else {
                    updatedBar = {
                        ...lastBar,
                        high: Math.max(lastBar.high, tradePrice),
                        low: Math.min(lastBar.low, tradePrice),
                        close: tradePrice,
                        volume: lastBar.volume + volume
                    }
                }

                lastBarsForSymbol.set(resolution, updatedBar)
                this.lastBarsCache.set(symbol, lastBarsForSymbol)
                subscriptionItem.onRealtimeCallback(updatedBar as Bar)
                document.title = `${(updatedBar.close).toFixed(subscriptionItem.symbolInfo.library_custom_fields!.digits)} | ${symbol} | ${this.applicationConfiguration.applicationTitle}`
            }
        }
    }

    private onMessageUpdateDepth(data: any): void {
        const {symbol, bid, ask, volume} = data
        for (const [, {symbol: subscribedSymbol, callback}] of this.depthSubscribers.entries()) {
            if (subscribedSymbol === symbol) {
                callback({
                    snapshot: false,
                    bids: [{price: bid, volume}],
                    asks: [{price: ask, volume}]
                })
            }
        }
    }

    private getNextBarTime(currentTime: number, resolution: string): number {
        const intervalMs = resolutionToMilliseconds(resolution as ResolutionString)
        return currentTime + intervalMs

        function resolutionToMilliseconds(resolution: ResolutionString): number {
            const resolutionNumber = parseInt(resolution)
            if (!isNaN(resolutionNumber)) return resolutionNumber * 60 * 1000
            else if (resolution === "D" || resolution === "1D") return 24 * 60 * 60 * 1000
            else if (resolution === "W" || resolution === "1W") return 7 * 24 * 60 * 60 * 1000
            else if (resolution === "M" || resolution === "1M") return 30 * 24 * 60 * 60 * 1000
            else return 60 * 1000
        }
    }

    private _mapperForSymbols(symbols: any): LibrarySymbolInfo[] {
        const {urlIconsSource} = this.applicationConfiguration
        return symbols.map((i: any) => {
            // const session = convertSessionsToTradingViewFormat(i.sessions)
            let logo_urls: string[] = getLogoUrls(i)

            return {
                name: i.symbol,
                ticker: i.symbol,
                currency_code: i.currency_base,
                original_currency_code: i.currency_base,
                visible_plots_set: "ohlcv",
                delay: 0,
                format: "price",
                volume_precision: 0,
                description: i.description,
                type: i.group,
                exchange: this.exchange,
                timezone: "Etc/UTC",
                listed_exchange: this.exchange,
                has_daily: true,
                has_empty_bars: false,
                has_weekly_and_monthly: true,
                has_volume: true,
                supported_resolutions: this.configuration.supported_resolutions as ResolutionString[],
                data_status: "streaming",
                session: i.watchlist_sessions,
                library_custom_fields: {
                    digits: i.digits,
                    originalSessions: i.sessions,
                    calc_mode: i.calc_mode,
                    contract_size: i.contract_size,
                    currency_base: i.currency_base,
                    currency_margin: i.currency_margin,
                    currency_profit: i.currency_profit,
                    volume_max_lots: i.volume_max_lots,
                    volume_min_lots: i.volume_min_lots,
                    volume_step_lots: i.volume_step_lots,
                    volume_limit_lots: i.volume_limit_lots
                },
                minmov: 1,
                minmov2: 0,
                fractional: false,
                pricescale: Math.pow(10, i.digits),
                has_intraday: true,
                has_ticks: true,
                has_seconds: true,
                build_seconds_from_ticks: true,
                logo_urls,
                exchange_logo: "/logo.png"
            }
        })

        function getLogoUrls(symbolObject: any): string[] {
            if (symbolObject.currency_base !== symbolObject.currency_profit) {
                return [
                    `${urlIconsSource}/${symbolObject.currency_base}.svg`,
                    `${urlIconsSource}/${symbolObject.currency_profit}.svg`
                ]
            } else {
                const accountsSuffixes = [".ecn", "#", "-", "USDT"]
                const regex = new RegExp(accountsSuffixes.join("|"), "g")
                const probablyLogoKey = symbolObject.symbol.replace(regex, "")
                return [`${urlIconsSource}/${probablyLogoKey}.svg`]
            }
        }
        // TODO: remove after testing on staging!
        // function convertSessionsToTradingViewFormat(sessions: { start: string, end: string }[][]): string {
        //     const dayMappingByTradingView = [2, 3, 4, 5, 6, 7, 1]
        //     return sessions
        //         .map((daySessions, dayIndex) => {
        //             const dayNumberByTradingView = dayMappingByTradingView[dayIndex]
        //             if (!daySessions || !daySessions.length) return null
        //             const intervals = daySessions.map(({start, end}) => {
        //                 const startFormatted = start.replace(":", "")
        //                 const endFormatted = end.replace(":", "")
        //                 return startFormatted === endFormatted || startFormatted < endFormatted
        //                     ? `${startFormatted}-${endFormatted}`
        //                     : `${startFormatted}F-${endFormatted}`
        //             })
        //             return `${intervals.join(",")}:${dayNumberByTradingView}`
        //         })
        //         .filter(Boolean)
        //         .join("|")
        // }
    }
}

