import {defaultConfig, IConfig} from "../../../context/config"
import {IAuth} from "../../../context/auth"
import {WebSocketManager} from "./abstract/WebSocketManager"

type TSymbol = string
type TTimestamp = number
type TBid = number
type TAsk = number
type TVolume = number

type TTickerData = [
    TSymbol, TTimestamp, TBid, TAsk, TVolume
]

export class WebSocketsTicker extends WebSocketManager {
    private applicationConfiguration: IConfig = defaultConfig
    private contextAuth!: IAuth
    private subscribers: Set<(data: any) => void> = new Set()
    public static instance: WebSocketsTicker | null = null

    constructor(applicationConfiguration: IConfig, contextAuth: IAuth) {
        super()
        this.applicationConfiguration = applicationConfiguration
        this.contextAuth = contextAuth
        super.connect()
    }

    static getInstance(
        applicationConfiguration: IConfig = defaultConfig,
        contextAuth: IAuth
    ): WebSocketsTicker {
        if (!WebSocketsTicker.instance) {
            WebSocketsTicker.instance = new WebSocketsTicker(applicationConfiguration, contextAuth)
        }
        return WebSocketsTicker.instance
    }

    subscribe(subscriber: (data: any) => void): void {
        this.subscribers.add(subscriber)
    }

    unsubscribe(subscriber: (data: any) => void): void {
        this.subscribers.delete(subscriber)
    }

    protected getEndpoint(): string {
        return this.applicationConfiguration.urlWebsocketTickerFullPath + "/" + this.contextAuth.loginAccountId
    }

    protected onMessage(event: MessageEvent): void {
        try {
            const [symbol, timestamp, bid, ask, volume]: TTickerData = JSON.parse(event.data)
            for (const subscriber of Array.from(this.subscribers)) {
                subscriber({symbol, timestamp, bid, ask, volume})
            }
        } catch (e) {
            if (event.data === "PING") return
            console.error("Ticks socket onmessage error:", event, e)
        }
    }

    public override disconnect(): void {
        super.disconnect()
        this.subscribers.clear()
        WebSocketsTicker.instance = null
    }
}
