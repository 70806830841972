import {defaultConfig, IConfig} from "../../../context/config"
import {IAuth} from "../../../context/auth"
import {WebSocketManager} from "./abstract/WebSocketManager"

export class WebSocketsTradeEvents extends WebSocketManager {
    private applicationConfiguration: IConfig = defaultConfig
    private contextAuth!: IAuth
    private subscribers: Set<(data: any) => void> = new Set()
    public static instance: WebSocketsTradeEvents | null = null

    constructor(applicationConfiguration: IConfig, contextAuth: IAuth) {
        super()
        this.applicationConfiguration = applicationConfiguration
        this.contextAuth = contextAuth
        super.connect()
    }

    static getInstance(
        applicationConfiguration: IConfig = defaultConfig,
        contextAuth: IAuth
    ): WebSocketsTradeEvents {
        if (!WebSocketsTradeEvents.instance) {
            WebSocketsTradeEvents.instance = new WebSocketsTradeEvents(applicationConfiguration, contextAuth)
        }
        return WebSocketsTradeEvents.instance
    }

    subscribe(subscriber: (data: any) => void): void {
        this.subscribers.add(subscriber)
    }

    unsubscribe(subscriber: (data: any) => void): void {
        this.subscribers.delete(subscriber)
    }

    protected getEndpoint(): string {
        return this.applicationConfiguration.urlWebsocketTradeEventsFullPath + "/" + this.contextAuth.loginAccountId
    }

    protected onMessage(event: MessageEvent): void {
        try {
            const data = JSON.parse(event.data)
            if (data.message_type === "TRADE") {
                for (const subscriber of Array.from(this.subscribers)) {
                    subscriber(data)
                }
            }
        } catch (e) {
            if (event.data === "PING") return
            console.error("Ticks socket onmessage error:", event, e)
        }
    }

    public override disconnect(): void {
        super.disconnect()
        this.subscribers.clear()
        WebSocketsTradeEvents.instance = null
    }
}
