import {RefObject, useContext, useEffect, useRef, useState} from "react"
import {
    widget,
    ResolutionString, TradingTerminalWidgetOptions, ThemeName, CustomThemes, Timezone,
    IChartingLibraryWidget
} from "../../assets/lib/charting_library"
import * as React from "react"
import {getLanguageFromURL} from "../../assets/utils/language"
import {Config} from "../../context/config"
import {DataFeed} from "./lib/DataFeed"
import {Auth} from "../../context/auth"
import {dark, light} from "./utils/theme"
import {IBrokerConnectionAdapterHost} from "../../assets/lib/charting_library/broker-api"
import {Broker} from "./lib/Broker"
import {getThemeFromUrlOrStorage} from "../../assets/utils/theme"
import {useSleepCheckWorkerLoopEngine} from "./hooks/useSleepCheckWorkerLoopEngine"
import {SaveLoadAdapter} from "./SaveLoadAdapter"
import {SettingsAdapter} from "./SettingsAdapter"
import {RemoteSettingsManager, Setting} from "../../settings/RemoteSettingsManager"
import translate from "./utils/translate"
import {CalculatorWrapper} from "../calculatorWrapper"
import styles from "./Tradingview.module.scss"
import {SideBarWrapper} from "../sideBarWrapper"
import {NewsFeed} from "./lib/NewsFeed"
import {$fetch} from "../../assets/utils/fetch"
import {WebSocketsTicker} from "./websockets/WebSocketsTicker"
import {WebSocketsTradeEvents} from "./websockets/WebSocketsTradeEvents"


const CHART_RESOLUTION_KEY = "chartResolution"
const CURRENT_SYMBOL_KEY = "current_symbol"

export const TradingViewWidget = () => {
    const contextAuth = useContext(Auth)
    const config = useContext(Config)
    const chartContainerRef = useRef<HTMLDivElement>(null) as RefObject<HTMLInputElement | null>
    const getThemeFromStorage = () => (localStorage.getItem("theme") || "light") as ThemeName
    const [datafeed, setDataFeed] = useState<DataFeed | null>(null)
    const [renderCount, setRenderCount] = useState<number>(0)
    const [theme, setTheme] = useState<ThemeName>(getThemeFromUrlOrStorage())
    const [isShowCalculator, setIsShowCalculator] = useState<boolean>(localStorage.getItem("isShowCalculator") === "true")

    const setIsShowCalculatorHandler = () => {
        localStorage.setItem("isShowCalculator", JSON.stringify(!isShowCalculator))
        setIsShowCalculator((prev) => !prev)
    }

    useSleepCheckWorkerLoopEngine(() => setRenderCount((prev) => prev + 1))

    useEffect(() => {
        WebSocketsTicker.getInstance(config, contextAuth)
        return () => WebSocketsTicker.getInstance(config, contextAuth).disconnect()
    }, [contextAuth.loginAccountId])

    useEffect(() => {
        WebSocketsTradeEvents.getInstance(config, contextAuth)
        return () => WebSocketsTradeEvents.getInstance(config, contextAuth).disconnect()
    }, [contextAuth.loginAccountId])

    useEffect(() => {
        DataFeed.removeInstance()
        const newInstance = DataFeed.getInstance(config, contextAuth)
        setDataFeed(newInstance)
        return () => DataFeed.removeInstance()
    }, [contextAuth.loginAccountId])

    useEffect(() => {
        if (!datafeed) return
        let widgetInstance: IChartingLibraryWidget
        let brokerInstance: any

        const setUpDefaultWatchlist = async () => {
            const defaultList = SettingsAdapter.defaultAdapter().defaultSymbols
            if (defaultList) {
                const api = await widgetInstance.watchList()
                const listId = api.getActiveListId()
                listId && api.updateList(listId, defaultList)
            }
        }

        const enableTradingViewCasingBugCorrection = async () => {
            const api = await widgetInstance.watchList()
            api.onListAdded().subscribe(null, (listId: string, symbols: string[]) => {
                const lookupTable: Record<string, string> = {}
                for (const symbolName of datafeed.getSymbolNames()) {
                    lookupTable[symbolName.toUpperCase()] = symbolName
                }

                const matchedSymbols: string[] = []
                for (const symbolName of symbols) {
                    if (symbolName.indexOf("###") === 0) {
                        matchedSymbols.push(symbolName) // always add sections
                    } else {
                        const existentSymbol = lookupTable[symbolName.toUpperCase()]
                        existentSymbol && matchedSymbols.push(existentSymbol)
                    }
                }

                setTimeout(() => {
                    api.updateList(listId, matchedSymbols)
                }, 0)
            })
        }

        const initializeWidget = async () => {
            const symbols = await datafeed.getSymbols()
            const allSymbols = await datafeed.getAllSymbols()
            await SettingsAdapter.setUpDefaultAdapter(config, contextAuth)
            const initialSymbol = await getInitialSymbolName(symbols)
            const chartStateAdapter = await SaveLoadAdapter.adapterForLogin(contextAuth.loginAccountId || 0)
            const newsfeed = NewsFeed.getInstance(allSymbols, config)

            const widgetOptions: TradingTerminalWidgetOptions = {
                debug: false,
                container: chartContainerRef.current!,
                datafeed,
                interval: (localStorage.getItem(CHART_RESOLUTION_KEY) || "60") as ResolutionString,
                timezone: config.userTimezone as Timezone,
                symbol: initialSymbol,
                custom_css_url: "/chartingLibraryCustom.css",
                library_path: "/charting_library/",
                locale: getLanguageFromURL(),
                autosize: true,
                load_last_chart: true,
                fullscreen: true,
                theme,
                enabled_features: [
                    "watchlist_sections",
                    "show_symbol_logos",
                    "show_exchange_logos",
                    "request_only_visible_range_on_reset",
                    "study_templates"
                ],
                disabled_features: [
                    "create_volume_indicator_by_default",
                    "create_volume_indicator_by_default_once",
                    "trading_notifications"
                ],
                widgetbar: {
                    details: true,
                    news: true,
                    watchlist: true,
                    datawindow: true,
                    watchlist_settings: {
                        default_symbols: [],
                        readonly: false
                    }
                },
                news_provider: newsfeed.newsProvider.bind(newsfeed),
                //@ts-ignore
                custom_translate_function: translate,
                //@ts-ignore
                broker_factory: (host: IBrokerConnectionAdapterHost) => {
                    brokerInstance = new Broker(host, datafeed, {
                        applicationConfiguration: config,
                        contextAuth,
                        symbols,
                        allSymbols,
                        webSocketsTradeEventsInstance: WebSocketsTradeEvents.getInstance(config, contextAuth)
                    })
                    return brokerInstance
                },
                // debug_broker: "broker-only",
                broker_config: {
                    configFlags: {
                        supportOrdersHistory: true,
                        supportNativeReversePosition: true,
                        supportClosePosition: true,
                        supportPLUpdate: true,
                        supportLevel2Data: true,
                        showQuantityInsteadOfAmount: true,
                        supportEditAmount: false,
                        supportOrderBrackets: true,
                        supportMarketBrackets: true,
                        supportPositionBrackets: true,
                        showNotificationsLog: false,
                        supportPartialClosePosition: true,
                        supportPartialCloseIndividualPosition: true
                    },
                    durations: [
                        {name: "GTC", value: "GTC"},
                        {name: "DAY", value: "DAY"}
                    ]
                },
                custom_themes: {
                    light: light,
                    dark: dark
                } as CustomThemes,
                overrides: {
                    "scalesProperties.showBidAskLabels": true
                },
                settings_adapter: SettingsAdapter.defaultAdapter(),
                save_load_adapter: chartStateAdapter
            }
            widgetInstance = new widget(widgetOptions)

            widgetInstance.onChartReady(async () => {

                const chart = widgetInstance.activeChart()
                chart.onSymbolChanged().subscribe(null, ((newSymbol: any) => {
                    saveInitialSymbolName(newSymbol.name)
                }) as (() => void))
                chart.onIntervalChanged().subscribe(null, (newResolution) => {
                    localStorage.setItem(CHART_RESOLUTION_KEY, newResolution)
                })
                widgetInstance.headerReady().then(() => {
                    widgetReadyCalculator(widgetInstance)
                    widgetReadyThemeToggle(widgetInstance)
                    widgetReadyLogout(widgetInstance)
                    widgetReadyButtonsNames(widgetInstance)
                })

                await widgetReadyHandlerForButtonActionToAttachCloseAll({
                    widget: widgetInstance,
                    querySelectorValue: ".brokerButton-hw_3o_pb.brokerButtonDefault-hw_3o_pb",
                    isHandledByMobileAction: true
                })
                await widgetReadyHandlerForButtonActionToAttachCloseAll({
                    widget: widgetInstance,
                    querySelectorValue: "[data-name=\"toggle-visibility-button\"]",
                    isHandledByMobileAction: false
                })
                await widgetReadyCloseAllPositionsButton(widgetInstance, false)
                await enableTradingViewCasingBugCorrection()
                await setUpDefaultWatchlist()
            })
        }

        initializeWidget()

        return () => {
            brokerInstance && brokerInstance.stopEngine()
            widgetInstance && widgetInstance.remove()
        }
    }, [datafeed, renderCount])

    const widgetReadyHandlerForButtonActionToAttachCloseAll = async ({
                                                                         widget,
                                                                         querySelectorValue,
                                                                         isHandledByMobileAction
                                                                     }: {
        widget: any,
        querySelectorValue: string,
        isHandledByMobileAction: boolean
    }) => {
        const doc = await waitForIFrameDocumentReady(widget)
        const btn = doc.querySelector(querySelectorValue) as any
        if (btn) {
            btn.onclick = function (e: any) {
                e.preventDefault()
                setTimeout(() => {
                    widgetReadyCloseAllPositionsButton(widget, isHandledByMobileAction)
                }, 100)
            }
        }
    }

    const widgetReadyCloseAllPositionsButton = async (widget: any, isHandledByMobileAction: boolean) => {
        const doc = await waitForIFrameDocumentReady(widget)
        const accountManagerElements = doc.querySelectorAll(`.js-account-manager-header`)

        const actionsBarElement = document.createElement("div")
        actionsBarElement.classList.add("customActionsBarManager")

        const closeALlPositionsElement = document.createElement("button")
        closeALlPositionsElement.textContent = "Close All Positions"
        closeALlPositionsElement.className = "closeAllPositionsButton button-mXzF5cTO lightButton-bYDQcOkp secondary-PVWoXu5j gray-PVWoXu5j xsmall-bYDQcOkp"
        closeALlPositionsElement.onclick = () => {
            widget.showConfirmDialog({
                title: "Confirmation",
                body: "Are you sure you want to close all positions?",
                mainButtonText: "Yes",
                cancelButtonText: "No",
                callback: (isApproved: any) => {
                    if (isApproved) {
                        $fetch.delete(`${config.urlRestAPI}/api/v1/orders/${contextAuth.loginAccountId}/close-all`)
                    }
                }
            })
        }

        accountManagerElements.forEach((el: any) => {
            if (el.parentNode.querySelector(".customActionsBarManager")) return
            if (!isHandledByMobileAction) {
                actionsBarElement.style.borderTop = "none"
                actionsBarElement.style.marginBottom = "8px"
            }
            actionsBarElement.appendChild(closeALlPositionsElement)
            el.parentNode.insertBefore(actionsBarElement, el)
        })
    }

    const widgetReadyButtonsNames = (widget: any) => {
        const el = widget._iFrame.contentWindow.document.querySelector(".container-hw_3o_pb")
        if (!el?.firstElementChild?.children) return
        const {firstElementChild: {children}} = el

        const [sell, buy] = [
            Array.from(children).find((el: any) => el.className.includes("sellButton-")),
            Array.from(children).find((el: any) => el.className.includes("buyButton-"))
        ]

        const addText = (parentElement: any, text: string) => {
            parentElement.style.height = "34px"
            parentElement.style.display = "flex"
            parentElement.style.flexDirection = "column"
            parentElement.style.gap = "1px"
            parentElement.style.fontWeight = "500"

            const div = document.createElement("div")
            div.style.fontSize = "11px"
            div.style.fontWeight = "500"
            div.textContent = text
            parentElement.appendChild(div)
        }

        addText(sell, "SELL")
        addText(buy, "BUY")
    }

    const waitForIFrameDocumentReady = (widget: any): Promise<Document> => {
        return new Promise((resolve) => {
            const doc = widget._iFrame.contentWindow.document
            if (doc.readyState === "complete") resolve(doc)
            else {
                const onReadyStateChange = () => {
                    if (doc.readyState === "complete") {
                        doc.removeEventListener("readystatechange", onReadyStateChange)
                        resolve(doc)
                    }
                }
                doc.addEventListener("readystatechange", onReadyStateChange)
            }
        })
    }

    const widgetReadyThemeToggle = (widget: any) => {
        const theme = getThemeFromStorage()
        const el = widget.createButton({
            align: "right"
        })
        el.dataset.internalAllowKeyboardNavigation = "true"
        el.id = "theme-toggle"
        el.title = "Toggle Theme"
        el.innerHTML = `
            <div class="theme-switch-wrapper">
                <label for="theme-switch" id="theme-switch-label">Dark Mode</label>
                <div class="switchWrap-bl9AR3Gv">
                    <span class="switcher-fwE97QDf">
                        <input id="theme-switch" tabindex="-1" type="checkbox" class="input-fwE97QDf activeStylesEnabled-fwE97QDf">
                        <span class="thumbWrapper-fwE97QDf">
                            <span class="switchView-CtnpmPzP small-CtnpmPzP">
                                <span class="track-CtnpmPzP"></span>
                                <span class="thumb-CtnpmPzP"></span>
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        `
        const checkboxEl = el.querySelector("#theme-switch")
        const switchViewEl = el.querySelector(".switchView-CtnpmPzP")

        checkboxEl.checked = theme === "dark"
        if (theme === "dark") switchViewEl.classList.add("checked-CtnpmPzP")

        checkboxEl.addEventListener("change", function () {
            const theme = getThemeFromStorage()
            const themeToSet = theme === "dark" ? "light" : "dark"
            localStorage.setItem("theme", themeToSet)
            document.documentElement.setAttribute("data-bs-theme", themeToSet)
            themeToSet === "dark"
                ? switchViewEl.classList.add("checked-CtnpmPzP")
                : switchViewEl.classList.remove("checked-CtnpmPzP")
            setTheme(themeToSet)
            widget.changeTheme(themeToSet, {disableUndo: false})
        })
    }

    const widgetReadyLogout = (widgetInstance: any) => {
        const logoutButton = widgetInstance.createButton({
            align: "right"
        })
        logoutButton.setAttribute("title", "Click to logout")
        logoutButton.classList.add("apply-common-tooltip")
        logoutButton.style.cursor = "pointer"
        logoutButton.addEventListener("click", () =>
            widgetInstance.showConfirmDialog({
                title: "Confirmation",
                body: "Are you sure you want to logout?",
                mainButtonText: "Logout",
                cancelButtonText: "Cancel",
                callback: (isApproved: any) => {
                    if (isApproved) contextAuth.logout()
                }
            })
        )
        logoutButton.innerHTML = "Logout"
    }

    const widgetReadyCalculator = (widgetInstance: any) => {
        const logoutButton = widgetInstance.createButton({
            align: "right"
        })
        logoutButton.setAttribute("title", "Calculator")
        logoutButton.classList.add("apply-common-tooltip")
        logoutButton.style.cursor = "pointer"
        logoutButton.addEventListener("click", () => setIsShowCalculatorHandler())

        logoutButton.innerHTML = `<svg height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="currentColor"><path d="M176,60H80a4,4,0,0,0-4,4v48a4,4,0,0,0,4,4h96a4,4,0,0,0,4-4V64A4,4,0,0,0,176,60Zm-4,48H84V68h88Zm28-80H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V40A12,12,0,0,0,200,28Zm4,188a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4H200a4,4,0,0,1,4,4ZM96,148a8,8,0,1,1-8-8A8,8,0,0,1,96,148Zm40,0a8,8,0,1,1-8-8A8,8,0,0,1,136,148Zm40,0a8,8,0,1,1-8-8A8,8,0,0,1,176,148ZM96,188a8,8,0,1,1-8-8A8,8,0,0,1,96,188Zm40,0a8,8,0,1,1-8-8A8,8,0,0,1,136,188Zm40,0a8,8,0,1,1-8-8A8,8,0,0,1,176,188Z"/></svg>`
    }

    const getInitialSymbolName = async (symbols: any[]): Promise<string> => {
        let setting: Setting | undefined = undefined

        try {
            setting = await RemoteSettingsManager.defaultManager().getValue(CURRENT_SYMBOL_KEY, contextAuth.loginAccountId!)
        } catch (error) {
            // Just folllow the fallback logic below
        }

        const savedSymbol = setting?.value
        return (
            symbols.find((symbol: any) => symbol.name === savedSymbol)?.name ||
            symbols.find((symbol: any) => symbol.name.includes("EURUSD"))?.name ||
            symbols[0]?.name ||
            savedSymbol
        )
    }

    const saveInitialSymbolName = React.useCallback(async (name: string) => {
        if (contextAuth.loginAccountId) {
            try {
                await RemoteSettingsManager.defaultManager().setValue(CURRENT_SYMBOL_KEY, contextAuth.loginAccountId, name)
            } catch (error) {
                // Not critical
            }
        }
    }, [contextAuth])

    return (
        <div className={styles.tradingViewWrapper}>
            <div
                ref={chartContainerRef}
                className={styles.tradingViewWidget}
            />
            <div>
                {
                    isShowCalculator && <SideBarWrapper
                        title={"Trading Calculator"}
                        closeCallback={() => setIsShowCalculatorHandler()}
                    >
                        <CalculatorWrapper
                            theme={theme}
                        ></CalculatorWrapper>
                    </SideBarWrapper>
                }
            </div>
        </div>
    )

}
