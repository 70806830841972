import React, { createContext, ReactNode } from 'react'


export interface IConfig {
    urlWebsocketTickerFullPath: string
    urlWebsocketTradeEventsFullPath: string
    urlRestAPI: string
    urlCalculator: string
    urlIconsSource: string
    applicationTitle: string
    userTimezone: string
}

export const defaultConfig: IConfig = {
    urlWebsocketTickerFullPath: '',
    urlWebsocketTradeEventsFullPath: '',
    urlRestAPI: '',
    applicationTitle: '',
    urlIconsSource: '',
    urlCalculator: '',
    userTimezone: ''
}

export const Config = createContext(defaultConfig)

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    const applicationTitle = process.env.REACT_APP_APPLICATION_TITLE || ''
    document.title = applicationTitle

    return (
        <Config.Provider value={ {
            urlWebsocketTickerFullPath: process.env.REACT_APP_URL_WEBSOCKET_TICKER_FULL_PATH,
            urlWebsocketTradeEventsFullPath: process.env.REACT_APP_URL_WEBSOCKET_TRADE_EVENTS_FULL_PATH,
            urlRestAPI: process.env.REACT_APP_URL_RESTAPI,
            urlIconsSource: process.env.REACT_APP_URL_ICONS_SOURCE,
            urlCalculator: process.env.REACT_APP_URL_CALCULATOR,
            userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            applicationTitle
        } as IConfig }>
            { children }
        </Config.Provider>
    )
}
