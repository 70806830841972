import {GetNewsResponse} from "../../../../public/charting_library"
import {LibrarySymbolInfo} from "../../../assets/lib/charting_library"
import {$fetch} from "../../../assets/utils/fetch"
import {IConfig} from "../../../context/config"

export class NewsFeed {
    private static instance: NewsFeed | null = null
    private symbolsAllMap: Map<string, LibrarySymbolInfo> = new Map()
    private readonly config: IConfig

    constructor(allSymbols: LibrarySymbolInfo[], config: IConfig) {
        allSymbols.forEach(symbol => this.symbolsAllMap.set(symbol.name, symbol))
        this.config = config
    }

    static getInstance(allSymbols: LibrarySymbolInfo[], config: IConfig): NewsFeed {
        if (!NewsFeed.instance) {
            NewsFeed.instance = new NewsFeed(allSymbols, config)
        }
        return NewsFeed.instance
    }

    static removeInstance(): void {
        NewsFeed.instance = null
    }

    async newsProvider(symbol: string, callback: (response: GetNewsResponse) => void) {
        const key_words = this.prepareKeyWords(symbol)
        let news = []
        try {
            const response = await $fetch.post(`${this.config.urlRestAPI}/api/v1/news/`, {
                body: JSON.stringify({
                    key_words
                })
            })
            const rawNews = await response.json()
            news = rawNews.map((item: any) => {
                return {
                    ...item,
                    published: new Date(item.published).toLocaleString("en-US", {timeZone: this.config.userTimezone})
                }
            })
        } catch (e) {
            console.error("News feed error", e)
        }

        callback({
            title: "News",
            newsItems: news
        })
    }

    private prepareKeyWords(symbol: string): string[] {
        const toReplaceNull = [".ecn", "#", "-", "USDT", "vs Tether"]
        const regex = new RegExp(toReplaceNull.join("|"), "g")

        const symbolInfo = this.symbolsAllMap.get(symbol)
        if (!symbolInfo) return [symbol]

        const replacedName = symbolInfo!.name.replace(regex, "")
        const replacedDescriptionsKeyWords = symbolInfo!.description.replace(regex, "").split("vs").map(i => i.trim())

        return [replacedName, ...replacedDescriptionsKeyWords]
    }
}