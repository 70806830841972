interface IGenericError extends Error {
    detail: string;
    code: number;
    context?: Record<string, any> | any;
    stack?: string;
}

export class GenericError extends Error implements IGenericError {
    detail: string
    code: number
    context?: Record<string, any> | any

    constructor(detail: string, code: number = 404, context?: Record<string, any> | any, stack?: string) {
        super(detail)
        this.name = "GenericError"
        this.detail = detail
        this.code = code
        this.context = context
        if (stack) {
            this.stack = stack
        } else if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor)
        }
    }
}