export abstract class WebSocketManager {
    protected socket: WebSocket | null = null
    protected socketReconnectAttempts: number = 0
    protected socketReconnectTimeoutId: number | null = null
    protected manualDisconnect: boolean = false


    protected abstract getEndpoint(): string;

    protected abstract onMessage(event: MessageEvent): void;

    protected abstract subscribe(subscriber: Function): void;

    protected abstract unsubscribe(subscriber: Function): void;

    protected onOpen(): void {
        console.log("WebSocket connection open", this.getEndpoint())
    }

    protected onClose(): void {
        console.log("WebSocket connection closed", this.getEndpoint())
    }

    protected onError(error: Event): void {
        console.error("WebSocket error:", this.getEndpoint(), error)
    }

    protected connect(): void {
        if (this.socket) return

        this.manualDisconnect = false
        const url = this.getEndpoint()
        this.socket = new WebSocket(url)

        this.socket.onopen = () => {
            this.socketReconnectAttempts = 0
            this.onOpen()
        }

        this.socket.onmessage = (event: MessageEvent) => {
            this.onMessage(event)
        }

        this.socket.onclose = () => {
            this.socket = null
            this.onClose()

            if (!this.manualDisconnect && this.socketReconnectAttempts < 5) {
                if (this.socketReconnectTimeoutId) {
                    clearTimeout(this.socketReconnectTimeoutId)
                }
                this.socketReconnectTimeoutId = window.setTimeout(() => this.connect(), 3500)
                this.socketReconnectAttempts++
            }
        }

        this.socket.onerror = (error) => {
            this.onError(error)
            this.socket = null
        }
    }

    public sendCommandJson(command: string, payload: Record<string, any> = {}): void {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            console.error("WebSocket is not open. Cannot sendCommandJson command.")
            return
        }
        const message = JSON.stringify({command, payload})
        this.socket.send(message)
    }

    public disconnect(): void {
        this.manualDisconnect = true
        if (this.socket) {
            this.socket.close()
            this.socket = null
        }
        if (this.socketReconnectTimeoutId) {
            clearTimeout(this.socketReconnectTimeoutId)
            this.socketReconnectTimeoutId = null
        }
    }
}
